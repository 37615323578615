module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bestforsikring.no","short_name":"Bestforsikring","start_url":"/","background_color":"#87d76b","theme_color":"#87d76b","display":"minimal-ui","icon":"src/images/LOGO/logo+green/Logo copy 2@4x.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-143242095-1","head":false,"anonymize":false,"respectDNT":false,"exclude":["/preview/**","/do-not-track/me/too/"],"pageTransitionDelay":0,"cookieDomain":"bestforsikring.no"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

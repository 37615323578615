// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-barn-2020-guide-js": () => import("./../../../src/pages/barn/2020/guide.js" /* webpackChunkName: "component---src-pages-barn-2020-guide-js" */),
  "component---src-pages-barn-2021-guide-js": () => import("./../../../src/pages/barn/2021/guide.js" /* webpackChunkName: "component---src-pages-barn-2021-guide-js" */),
  "component---src-pages-barn-index-js": () => import("./../../../src/pages/barn/index.js" /* webpackChunkName: "component---src-pages-barn-index-js" */),
  "component---src-pages-barn-sammenlign-js": () => import("./../../../src/pages/barn/sammenlign.js" /* webpackChunkName: "component---src-pages-barn-sammenlign-js" */),
  "component---src-pages-bat-fritidsbat-2020-guide-js": () => import("./../../../src/pages/bat/fritidsbat/2020/guide.js" /* webpackChunkName: "component---src-pages-bat-fritidsbat-2020-guide-js" */),
  "component---src-pages-bat-fritidsbat-2021-guide-js": () => import("./../../../src/pages/bat/fritidsbat/2021/guide.js" /* webpackChunkName: "component---src-pages-bat-fritidsbat-2021-guide-js" */),
  "component---src-pages-bat-fritidsbat-index-js": () => import("./../../../src/pages/bat/fritidsbat/index.js" /* webpackChunkName: "component---src-pages-bat-fritidsbat-index-js" */),
  "component---src-pages-bat-fritidsbat-sammenlign-js": () => import("./../../../src/pages/bat/fritidsbat/sammenlign.js" /* webpackChunkName: "component---src-pages-bat-fritidsbat-sammenlign-js" */),
  "component---src-pages-bat-index-js": () => import("./../../../src/pages/bat/index.js" /* webpackChunkName: "component---src-pages-bat-index-js" */),
  "component---src-pages-bat-turbat-2020-guide-js": () => import("./../../../src/pages/bat/turbat/2020/guide.js" /* webpackChunkName: "component---src-pages-bat-turbat-2020-guide-js" */),
  "component---src-pages-bat-turbat-2021-guide-js": () => import("./../../../src/pages/bat/turbat/2021/guide.js" /* webpackChunkName: "component---src-pages-bat-turbat-2021-guide-js" */),
  "component---src-pages-bat-turbat-index-js": () => import("./../../../src/pages/bat/turbat/index.js" /* webpackChunkName: "component---src-pages-bat-turbat-index-js" */),
  "component---src-pages-bat-turbat-sammenlign-js": () => import("./../../../src/pages/bat/turbat/sammenlign.js" /* webpackChunkName: "component---src-pages-bat-turbat-sammenlign-js" */),
  "component---src-pages-bil-2019-guide-js": () => import("./../../../src/pages/bil/2019/guide.js" /* webpackChunkName: "component---src-pages-bil-2019-guide-js" */),
  "component---src-pages-bil-2020-guide-js": () => import("./../../../src/pages/bil/2020/guide.js" /* webpackChunkName: "component---src-pages-bil-2020-guide-js" */),
  "component---src-pages-bil-2021-guide-js": () => import("./../../../src/pages/bil/2021/guide.js" /* webpackChunkName: "component---src-pages-bil-2021-guide-js" */),
  "component---src-pages-bil-index-js": () => import("./../../../src/pages/bil/index.js" /* webpackChunkName: "component---src-pages-bil-index-js" */),
  "component---src-pages-bil-sammenlign-js": () => import("./../../../src/pages/bil/sammenlign.js" /* webpackChunkName: "component---src-pages-bil-sammenlign-js" */),
  "component---src-pages-hus-2019-guide-js": () => import("./../../../src/pages/hus/2019/guide.js" /* webpackChunkName: "component---src-pages-hus-2019-guide-js" */),
  "component---src-pages-hus-2020-guide-js": () => import("./../../../src/pages/hus/2020/guide.js" /* webpackChunkName: "component---src-pages-hus-2020-guide-js" */),
  "component---src-pages-hus-2021-guide-js": () => import("./../../../src/pages/hus/2021/guide.js" /* webpackChunkName: "component---src-pages-hus-2021-guide-js" */),
  "component---src-pages-hus-index-js": () => import("./../../../src/pages/hus/index.js" /* webpackChunkName: "component---src-pages-hus-index-js" */),
  "component---src-pages-hus-sammenlign-js": () => import("./../../../src/pages/hus/sammenlign.js" /* webpackChunkName: "component---src-pages-hus-sammenlign-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-innbo-2019-guide-js": () => import("./../../../src/pages/innbo/2019/guide.js" /* webpackChunkName: "component---src-pages-innbo-2019-guide-js" */),
  "component---src-pages-innbo-2020-guide-js": () => import("./../../../src/pages/innbo/2020/guide.js" /* webpackChunkName: "component---src-pages-innbo-2020-guide-js" */),
  "component---src-pages-innbo-2021-guide-js": () => import("./../../../src/pages/innbo/2021/guide.js" /* webpackChunkName: "component---src-pages-innbo-2021-guide-js" */),
  "component---src-pages-innbo-index-js": () => import("./../../../src/pages/innbo/index.js" /* webpackChunkName: "component---src-pages-innbo-index-js" */),
  "component---src-pages-innbo-sammenlign-js": () => import("./../../../src/pages/innbo/sammenlign.js" /* webpackChunkName: "component---src-pages-innbo-sammenlign-js" */),
  "component---src-pages-reise-2019-guide-js": () => import("./../../../src/pages/reise/2019/guide.js" /* webpackChunkName: "component---src-pages-reise-2019-guide-js" */),
  "component---src-pages-reise-2020-guide-js": () => import("./../../../src/pages/reise/2020/guide.js" /* webpackChunkName: "component---src-pages-reise-2020-guide-js" */),
  "component---src-pages-reise-2021-guide-js": () => import("./../../../src/pages/reise/2021/guide.js" /* webpackChunkName: "component---src-pages-reise-2021-guide-js" */),
  "component---src-pages-reise-index-js": () => import("./../../../src/pages/reise/index.js" /* webpackChunkName: "component---src-pages-reise-index-js" */),
  "component---src-pages-reise-sammenlign-js": () => import("./../../../src/pages/reise/sammenlign.js" /* webpackChunkName: "component---src-pages-reise-sammenlign-js" */),
  "component---src-templates-barneforsikring-detaljer-js": () => import("./../../../src/templates/barneforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-barneforsikring-detaljer-js" */),
  "component---src-templates-bilforsikring-detaljer-js": () => import("./../../../src/templates/bilforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-bilforsikring-detaljer-js" */),
  "component---src-templates-forsikringstilbyder-detaljer-js": () => import("./../../../src/templates/forsikringstilbyder-detaljer.js" /* webpackChunkName: "component---src-templates-forsikringstilbyder-detaljer-js" */),
  "component---src-templates-fritidsbatforsikring-detaljer-js": () => import("./../../../src/templates/fritidsbatforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-fritidsbatforsikring-detaljer-js" */),
  "component---src-templates-husforsikring-detaljer-js": () => import("./../../../src/templates/husforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-husforsikring-detaljer-js" */),
  "component---src-templates-innboforsikring-detaljer-js": () => import("./../../../src/templates/innboforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-innboforsikring-detaljer-js" */),
  "component---src-templates-reiseforsikring-detaljer-js": () => import("./../../../src/templates/reiseforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-reiseforsikring-detaljer-js" */),
  "component---src-templates-turbatforsikring-detaljer-js": () => import("./../../../src/templates/turbatforsikring-detaljer.js" /* webpackChunkName: "component---src-templates-turbatforsikring-detaljer-js" */)
}

